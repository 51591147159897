import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const AUTH_TOKEN = 'apollo-token'
const dataState = createPersistedState({
  key: 'vuex',
    reducer (val) {
      if(!sessionStorage.getItem(AUTH_TOKEN)) { // val.user.token (your user token for example)
        return {}
      }
      return val
    },
  storage: window.sessionStorage,
})
export default new Vuex.Store({

  plugins: [dataState],
  state: { 
    filtre:"",   
    changed: false,
    parametres: [],
    entites: [],
    me: {}, 
    auth: [],   
    isEditing: false,
    document:{},
    extensions:[],
    products_types: [], 
    tiers_types: [], 
    alerts:[],
    depots: [],  
    exercices: [],  
    structures: [], 
    options: [],   
    isadmin: false,
    exercice:null,  
    today:null,
    users: [],
  },
  mutations: {
    set_extensions(state, value) {      
      state.extensions = value;
    },
    set_users(state, value) {      
      state.users = value;
    },
    set_today(state, value) {      
      state.today = value;
    },
    set_document(state, value) {      
      state.document = value;
    },
    set_editing(state, value) {      
      state.isEditing = value;
    },
    set_exercices(state, value) {      
      state.exercices = value;
    },
    set_filtre(state, value) {            
      state.filtre = value;
    },
    set_entites(state, value) {
      state.entites = value;    
    },   
    set_options(state, value) {
      state.options = value;
    },
    set_products_types(state, value) {
      state.products_types = value;
    },
    set_tiers_types(state, value) {
      state.tiers_types = value;
    },
    set_structures(state, value) {
      state.structures = value;
    },
    set_alerts(state, value) {
      state.alerts = value;
    },
    set_depots(state, value) {
      state.depots = value;
    },
    set_changed(state, value){
      state.changed = value;
    },
    ActualUser(state, user) {
      let a = user.autorisations ? user.autorisations.split(",") : []
      let b=""
    
      if (user.profile)
      b = user.profile.autorisations ? user.profile.autorisations.split(",") : []
      state.auth = a.concat(b)
     
      state.me = user;
      state.isadmin = user.profile_id == 1 ? true : false
    },
    set_parametres(state, parametres) {
      state.parametres = parametres;
    },  
    set_exercice(state, exercice) {
      state.exercice = parseInt(exercice);
    }, 
    },
   
  actions: {
    Editing(context, value) {
      context.commit("set_editing", value);      
    },
    Document(context, value) {
      context.commit("set_document", value);      
    },
    Entite(context, value) {
      context.commit("set_entites", value);
    },
    Filtre(context, value) {
      context.commit("set_filtre", value);
    },
   
    Changed(context, value) {
      context.commit("set_changed", value);
    },
    ActualUser(context, user) {
      context.commit("ActualUser", user);
    },
    LoadOptions(context,data) {
      context.commit("set_options", data.options);   
      context.commit("set_products_types", data.products_types);
      context.commit("set_tiers_types", data.tiertypes); 
      context.commit("set_alerts", data.alerts_list);    
      context.commit("set_depots", data.depots);
      context.commit("set_exercices", data.exercices);
      context.commit("set_extensions", data.extensions);
      context.commit("set_structures", data.structures);
      context.commit("set_users", data.users);
      context.commit("set_exercice", data.getdate[0].today.slice(0, 4)); 
      context.commit("set_today", data.getdate[0].today.slice(0, 10));       
      
    },   
  },
  
  modules: {}
});
