
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import decode from 'jwt-decode'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { onError } from "apollo-link-error";
import { httpEndpoint } from "print/data.js";
import { ApolloLink } from 'apollo-link';
import router from "./router";


Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'
const CACHE_VUEX = 'vuex-store'


//Sets a global error handler using the Apollo Error Link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)

    if (graphQLErrors[0]) {

      if (graphQLErrors[0].extensions.category === 'authentication') {
        if (router.currentRoute.path != "/") router.push("/")
      }
    }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})
//Combines the Apollo Http and Error links
const link = ApolloLink.from([
  errorLink,
]);



// Config
const defaultOptions = {

  httpEndpoint,
  tokenName: AUTH_TOKEN,
  persisting: false,
  link: link,
  cache: new InMemoryCache(),

}

// Call this in the Vue app file
export function createProvider (options = {}) {
  
  // Create apollo client
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  })


  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
      },

    },
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })

  return apolloProvider
}


export function getAuthToken () {
  return sessionStorage.getItem(AUTH_TOKEN)
}

export function isLoggedIn () {
  let authToken = getAuthToken()
  return !!authToken && !isTokenExpired(authToken)
}
export function getexpiredate () {
  return getTokenExpirationDate(sessionStorage.getItem(AUTH_TOKEN))
}
export function getUserInfo () {
  if (isLoggedIn()) {
    return decode(getAuthToken())
  }
}

function getTokenExpirationDate (encodedToken) {
  let token = decode(encodedToken)
  if (!token.exp) {
    return null
  }

  let date = new Date(0)
  date.setUTCSeconds(token.exp)
  return date
}

function isTokenExpired (token) {
  let expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}


export async function onLogin (apolloClient, token) {
  if (token) {
    sessionStorage.setItem(AUTH_TOKEN, token)
    localStorage.setItem(AUTH_TOKEN, token)
  }

  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof sessionStorage !== 'undefined') {

    sessionStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem(AUTH_TOKEN)
    sessionStorage.removeItem(CACHE_VUEX)
    //window.location.reload();
  }

  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
